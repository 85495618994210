@import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';

 

/* Import modern, professional fonts */
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;500;600;700&family=Source+Sans+3:wght@300;400;500;600&family=Montserrat:wght@400;500;600;700&display=swap');

:root {
  /* Typography Scale */
  --font-scale-xs: 0.75rem;    /* 12px */
  --font-scale-sm: 0.875rem;   /* 14px */
  --font-scale-base: 1rem;     /* 16px */
  --font-scale-lg: 1.125rem;   /* 18px */
  --font-scale-xl: 1.25rem;    /* 20px */
  --font-scale-2xl: 1.5rem;    /* 24px */
  --font-scale-3xl: 1.875rem;  /* 30px */
  --font-scale-4xl: 2.25rem;   /* 36px */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans 3', sans-serif;  /* Modern, clean body font */
  /* background-color: #d0a657; */
  min-height: 100vh;
  color: rgb(6, 2, 2);
  font-size: var(--font-scale-base);
  line-height: 1.6;
  font-weight: 400;
}

/* Main Headings - Using Cormorant for luxury/travel feel */
.heading-primary {
  font-family: 'Cormorant', serif;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1.2;
}

/* Secondary Headings */
.heading-secondary {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.3;
}

/* Navigation */
.nav-link {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: var(--font-scale-sm);
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

/* Brand/Logo Text */
.brand-text {
  font-family: 'Cormorant', serif;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-transform: none;
}

/* Feature Text (for highlights, testimonials) */
.feature-text {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 300;
  font-size: var(--font-scale-lg);
  line-height: 1.8;
}

/* Call to Action Text */
.cta-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

/* Caption Text (for images, small details) */
.caption-text {
  font-family: 'Source Sans 3', sans-serif;
  font-size: var(--font-scale-sm);
  font-weight: 400;
  letter-spacing: 0.01em;
}




/* global.css or in your component file */


/* global.css or in your component file */
 .mission-container {
  /* Add styles specific to the mission container */
  background-color: #f8f8f8; /* Example background color */
  padding: 20px; /* Example padding */
  border-radius: 8px; /* Example border radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Example box shadow for depth */
}

/* Style the heading within the mission container */
 .mission-container h2 {
  color: black; /* Example text color for the heading */
}

/* Optional: Add styles for the paragraph within the mission container */
.mission-container p {
  margin-top: 10px; /* Example margin-top for spacing */
}

.high-contrast {
  background-color: black;
  color: white;
}
.map-container {
  width: 100%;
  height: 100vh;
}

.map {
  width: 100%;
  height: 100%;
}

.highlight {
  background-color: yellow;
}

@tailwind base;
@tailwind components;
@tailwind utilities;